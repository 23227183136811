import Image from 'next/image';

import ImData from '../../illustrations/im-data.svg';
import ListLoadingSpinner from '../ui/animation/ListLoadingSpinner';

function NoDataAvailableYet(props: { title: string; subtitle?: string; isLoading?: boolean }): JSX.Element {
    const { title, subtitle, isLoading = false } = props;

    if (isLoading) {
        return <ListLoadingSpinner />;
    }

    return (
        <div className="text-center space-y-24">
            <div className="max-w-3xl mx-auto">
                <Image
                    src={ImData.src}
                    layout="responsive"
                    width={250}
                    height={100}
                    alt="no-data"
                />
            </div>
            <div className="space-y-3">
                <h3 className="text-body-bold-16">{title}</h3>
                {subtitle && <p className="text-body-14">{subtitle}</p>}
            </div>
        </div>
    );
}

export default NoDataAvailableYet;
