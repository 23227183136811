import classnames from 'classnames';

import SkeletonView, { Skeletonable } from '../skeleton-view/SkeletonView';

type InfoCardSmallProps = {
    label: string;
    subLabel?: string;
    value: number | string | JSX.Element;
    icon?: JSX.Element;
    className?: string;
    labelClassName?: string;
    valueClassName?: string;
    labelUppercase?: boolean;
} & Skeletonable;

function InfoCardSmallNew(props: InfoCardSmallProps) {
    const { label, subLabel, icon, value, className, valueClassName, labelClassName, isLoading, labelUppercase } =
        props;

    return (
        <div className={classnames('flex-1 text-white-50', className)}>
            <div
                className={classnames(labelClassName, 'text-body-bold-10 tracking-wider whitespace-pre-wrap', {
                    uppercase: labelUppercase,
                    'flex flex-col': subLabel
                })}>
                {label && <span>{label}</span>}
                {subLabel && <span>{subLabel}</span>}
            </div>
            <div className="flex">
                {icon && <div className="mr-10 p-1">{icon}</div>}
                <div className={classnames(valueClassName || 'text-cyan text-headline-18')}>
                    {isLoading ? <SkeletonView /> : value}
                </div>
            </div>
        </div>
    );
}

export default InfoCardSmallNew;
