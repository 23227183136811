import {
    CategoryScale,
    ChartData,
    ChartDataset,
    Chart as ChartJS,
    ChartOptions,
    DefaultDataPoint,
    LineElement,
    LinearScale,
    PointElement,
    Tooltip
} from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';

export type LineChartStyle = {
    ticks?: {
        color?: string;
        font?: { family?: string; size?: number };
    };
    borderColor?: string;
    legend?: boolean;
    tension?: 0.4;
};

export type LineChartData<TData, TLabel> = {
    y: TData;
    x: TLabel;
};

type LineChartProps<TData, TLabel> = {
    data: LineChartData<TData, TLabel>[];
    datasetLabel?: string;
    style?: LineChartStyle;
};

export default function LineChart<TData = DefaultDataPoint<'line'>, TLabel = unknown>(
    props: LineChartProps<TData, TLabel>
) {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

    const { data: dataProp, datasetLabel } = props;

    const style: LineChartStyle = {
        ticks: {
            color: 'white'
        },
        borderColor: 'cyan',
        ...(props.style || {})
    };

    const options: ChartOptions<'line'> = {
        scales: {
            x: {
                ticks: {
                    ...style?.ticks
                },
                offset: true
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false
                },
                ticks: {
                    ...style?.ticks,
                    stepSize: 1
                }
            }
        },
        plugins: {
            legend: {
                display: style.legend ? style.legend : true
            }
        }
    };

    const datasets: ChartDataset<'line', TData[]>[] = [
        {
            label: datasetLabel,
            data: dataProp.map((label) => label.y),
            borderColor: style?.borderColor,
            backgroundColor: style?.borderColor,
            tension: props.style.tension || 0.0,
            pointHitRadius: 0,
            fill: false,
            pointRadius: 1.5
        }
    ];

    const data: ChartData<'line', TData[], TLabel> = {
        labels: dataProp.map((label) => label.x),
        datasets: datasets
    };

    return (
        <Line
            className="relative w-full"
            data={data}
            options={options}
        />
    );
}
