import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconRightArrow48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="49" 
             height="48" 
             viewBox="0 0 49 48"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M48.4142 24L30.7365 41.6777L29.3223 40.2635L45.5858 24L29.3223 7.73654L30.7365 6.32233L48.4142 24Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M-4.29288e-06 23L47 23L47 25L-3.8147e-06 25L-4.29288e-06 23Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconRightArrow24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="25" 
             height="24"
             viewBox="0 0 25 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M21.5858 12L13.8076 19.7782L15.2218 21.1924L24.4142 12L15.2218 2.80761L13.8076 4.22182L21.5858 12Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M3.37589e-08 11L22 11L22 13L0 13L3.37589e-08 11Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconRightArrow16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16" 
             viewBox="0 0 16 16" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.4143 8L9.70714 13.7071L8.29294 12.2929L12.5859 8L8.29295 3.70711L9.70716 2.29289L15.4143 8Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.0108e-06 7L13 7L13 9L9.53674e-07 9L1.0108e-06 7Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}