import { TFunction, useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getUsersCount, getUsersCountGrouped } from '../../../api/analytics/analytics-api';
import { AnalyticsApiGroup } from '../../../api/analytics/analytics-api.group';
import {
    dateTodayMinus,
    formatDate,
    formatDate_yyyyMMdd,
    formatDate_yyyyMMddTHHmmssZ
} from '../../../helper/date-helper';
import { IconRightArrow16 } from '../../../icons/arrows/ic-rightArrow';
import { CinemaDto } from '../../../models/cinema/CinemaDto.types';
import NoDataAvailableYet from '../../misc/NoDataAvailableYet';
import Button, { ButtonStyle } from '../../ui/buttons/Button';
import AdminCard from '../../ui/cards/AdminCard';
import InfoCardSmallNew from '../../ui/cards/InfoCardSmallNew';
import LineChart, { LineChartData, LineChartStyle } from '../../ui/chart/LineChart';
import LinkWithStyle from '../../ui/link/LinkWithStyle';
import Tabs from '../../ui/tabs/Tabs';

export type DashboardUserGrowthProps = {
    cinema?: CinemaDto;
    lineChartStyle?: LineChartStyle;
    moreAnalyticsUrl?: string;
    locale?: string;
    t: TFunction;
};

function DashboardUserGrowth(props: DashboardUserGrowthProps): JSX.Element {
    const { cinema, lineChartStyle, moreAnalyticsUrl, locale } = props;

    const t = props.t || useTranslation(['dashboard', 'common']).t;

    const [usersGroupLastUpdatedDatetime, setUsersGroupLastUpdatedDatetime] = useState<Date>();
    const [lastNDays, setLastNDays] = useState<30 | 90 | 365>(30);
    const [newUsersCurrentMonth, setNewUsersCurrentMonth] = useState<number>(undefined);

    const { data: totalUsersCount, isLoading: isLoadingTotalUsersCount } = useQuery(
        ['total-users-count', cinema],
        async () => {
            return await getUsersCount({ favoriteCinemaId: cinema?.id });
        },
        {
            staleTime: 60000
        }
    );

    const { data: usersGroupResponse, isLoading: isLoadingUsersGroupResponse } = useQuery(
        ['users-group-response', cinema, lastNDays],
        async () => {
            const startDate = dateTodayMinus(lastNDays);
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date();
            endDate.setHours(23, 59, 59, 0);
            return await getUsersCountGrouped({
                registerDatetimeStart: formatDate_yyyyMMddTHHmmssZ(startDate),
                registerDatetimeEnd: formatDate_yyyyMMddTHHmmssZ(endDate),
                favoriteCinemaId: cinema?.id,
                group: AnalyticsApiGroup.Day
            });
        }
    );

    const usersGroupsData = usersGroupResponse || [];

    function getUsersGroupsDataItems(lastNDays: 30 | 90 | 365): LineChartData<number, string>[] {
        let data = Array.from(Array(lastNDays).keys()).map((index) => {
            const todayMinusNDays = dateTodayMinus(index);
            todayMinusNDays.setHours(0, 0, 0, 0);
            const usersGroupItem = usersGroupsData.find(
                (usersGroupItem) =>
                    formatDate_yyyyMMdd(new Date(usersGroupItem.group)) === formatDate_yyyyMMdd(todayMinusNDays)
            );
            if (usersGroupItem) {
                return {
                    x: usersGroupItem.group as string,
                    y: usersGroupItem.count
                };
            }
            return {
                x: formatDate_yyyyMMdd(todayMinusNDays),
                y: 0
            };
        });

        if (lastNDays === 365) {
            data = data.reduce((current, item) => {
                const monthForItem = new Date(item.x).getMonth();
                if (!(monthForItem in current)) {
                    current[monthForItem] = {
                        x: item.x.substring(0, 7),
                        y: item.y
                    };
                } else {
                    current[monthForItem] = {
                        x: current[monthForItem].x,
                        y: current[monthForItem].y + item.y
                    };
                }
                return current;
            }, []);
            // } else if (lastNDays === 90) {
            //     data = data.reduce((current, item) => {
            //         const itemDate = new Date(item.x);
            //         const startDate = new Date(itemDate.getFullYear(), 0, 1, 0);
            //         const days = Math.floor((itemDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
            //         const weekNumber = Math.ceil(days / 7);
            //         if (!(weekNumber in current)) {
            //             current[weekNumber] = {
            //                 x: weekNumber,
            //                 y: item.y
            //             };
            //         } else {
            //             current[weekNumber] = {
            //                 x: current[weekNumber].x,
            //                 y: current[weekNumber].y + item.y
            //             };
            //         }
            //         return current;
            //     }, []);
        }
        return data.sort((itemA, itemB) => (itemA.x < itemB.x ? -1 : 1));
    }

    function getUsersCountLastNDaysLabel(): string {
        let label: string = t('dashboard:new-users');
        switch (lastNDays) {
            case 365:
                label += ` (${t('dashboard:last-year')})`;
                break;
            case 90:
                label += ` (${t('dashboard:last-90-days')})`;
                break;
            default:
                label += ` (${t('dashboard:last-30-days')})`;
                break;
        }
        return label;
    }

    function getUsersCountLastNDays(): number {
        return usersGroupsData.reduce((current, item) => current + item.count, 0);
    }

    useEffect(() => {
        if (usersGroupResponse) {
            setUsersGroupLastUpdatedDatetime(new Date());
        }
        const newUsersCurrentMonthUpdated = usersGroupsData
            .filter((usersGroupItem) => new Date(usersGroupItem.group).getDate() === new Date().getDate())
            .reduce((current, it) => current + it.count, 0);
        if (
            usersGroupResponse &&
            (newUsersCurrentMonth === undefined || newUsersCurrentMonth !== newUsersCurrentMonthUpdated)
        ) {
            setNewUsersCurrentMonth(newUsersCurrentMonthUpdated);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersGroupResponse]);

    return (
        <div className="space-y-24 xl:space-y-0 flex flex-col xl:space-x-24 xl:flex-row w-full">
            <AdminCard
                className="relative w-full flex flex-col"
                title={t('dashboard:user-analysis')}
                trailingComponent={
                    <span className="text-body-12 text-white-50">{`${t('dashboard:last-updated')}: ${
                        usersGroupLastUpdatedDatetime
                            ? formatDate(usersGroupLastUpdatedDatetime, locale, {
                                  year: 'numeric',
                                  day: '2-digit',
                                  month: '2-digit',
                                  hour: '2-digit',
                                  minute: '2-digit'
                              })
                            : '-'
                    }`}</span>
                }>
                <div className="flex-auto flex flex-col xl:flex-row xl:items-start space-y-16 xl:space-y-0">
                    <div className="flex flex-col space-y-24 w-200">
                        <InfoCardSmallNew
                            label={t('dashboard:total-users')}
                            value={totalUsersCount}
                            isLoading={isLoadingTotalUsersCount}
                        />
                        <InfoCardSmallNew
                            label={t('dashboard:estimated-number-of-people-reached')}
                            value={Math.ceil(totalUsersCount * 2.7)}
                            isLoading={isLoadingTotalUsersCount}
                        />
                        <InfoCardSmallNew
                            label={getUsersCountLastNDaysLabel()}
                            value={getUsersCountLastNDays()}
                            isLoading={isLoadingUsersGroupResponse}
                        />
                    </div>

                    <div className="xl:w-0 flex-auto flex flex-col space-y-24">
                        <div className="flex flex-col xl:flex-row xl:justify-between xl:items-center space-y-8 xl:space-y-0">
                            <span className="text-body-bold-14">{t('dashboard:user-growth')}</span>
                            <Tabs
                                tabs={[
                                    t('dashboard:last-30-days'),
                                    t('dashboard:last-90-days'),
                                    t('dashboard:last-year')
                                ]}
                                onTabItemClick={(index) => {
                                    switch (index) {
                                        case 1:
                                            setLastNDays(90);
                                            break;
                                        case 2:
                                            setLastNDays(365);
                                            break;
                                        default:
                                            setLastNDays(30);
                                    }
                                }}
                            />
                        </div>
                        {usersGroupsData?.length > 0 ? (
                            <LineChart
                                data={getUsersGroupsDataItems(lastNDays)}
                                datasetLabel={t('dashboard:users')}
                                style={lineChartStyle}
                            />
                        ) : (
                            <NoDataAvailableYet
                                title={t('common:no-data-yet')}
                                subtitle={t('dashboard:user-analysis-no-data-yet')}
                                isLoading={isLoadingUsersGroupResponse}
                            />
                        )}
                        {moreAnalyticsUrl && (
                            <LinkWithStyle
                                className="ml-auto text-cyan"
                                href={moreAnalyticsUrl}>
                                <Button
                                    style={ButtonStyle.text_only}
                                    trailingIcon={<IconRightArrow16 />}>
                                    {t('dashboard:more-analytics')}
                                </Button>
                            </LinkWithStyle>
                        )}
                    </div>
                </div>
            </AdminCard>
        </div>
    );
}

export default DashboardUserGrowth;
