import { PurchaseReasonEnum } from '../../constants/PurchaseReason.enum';
import { EventRequestState } from '../../constants/request-constants';
import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';
import { CommentsCountCountDto } from '../../models/analytics/CommentsCountCountDto.types';
import { EventRequestCountDto } from '../../models/analytics/EventRequestCountDto.types';
import { MessagesCountDto } from '../../models/analytics/MessagesCountDto.types';
import { MessagesCountGroupedDto } from '../../models/analytics/MessagesCountGroupedDto.types';
import { TicketPurchaseIntentsCountDto } from '../../models/analytics/TicketPurchaseIntentsCountDto.types';
import { TicketsPurchaseIntentsCountGrouped } from '../../models/analytics/TicketsPurchaseIntentsCountGrouped.types';
import { UserMoviesCountDto } from '../../models/analytics/UserMoviesCountDto.types';
import { UserMoviesCountGroupedDto } from '../../models/analytics/UserMoviesCountGroupedDto.types';
import { UsersGroupCountDto } from '../../models/analytics/UsersGroupCountDto.types';
import { DeviceType } from '../../types/DeviceType.types';
import apiClient from '../cineamoApiClient';

import { AnalyticsApiGroup } from './analytics-api.group';

// <------------------------ Comments Count ------------------------>

type GetCommentsCountQueryParameter = UrlQueryParameter & {
    userId?: number | string;
};

/**
 * This endpoint returns the number of comments.
 *
 * @example
 * // Get the number of comments of a specific user:
 * getCommentsCount({userId: 1});
 *
 * @param params
 * @returns {number}
 */
export async function getCommentsCount(params?: GetCommentsCountQueryParameter): Promise<number> {
    return apiClient
        .get<CommentsCountCountDto>('/analytics/comments-count' + buildUrlQuery(params))
        .then((response) => response.data.count)
        .catch(() => {
            return null;
        });
}

// <------------------------ Event Request Count ------------------------>

type GetEventRequestCountQueryParameter = UrlQueryParameter & {
    cineamoMovieId?: string;
    cinemaId?: string;
    state?: EventRequestState;
    isPrivate?: boolean;
    isCreatedByCinema?: boolean;
    isRegularShowtime?: boolean;
    category?: number;
    userId?: number | string;
};

/**
 * This endpoint returns the number of event requests.
 *
 * @example
 * // Get the number of event requests of a specific cinema:
 * getEventRequestCount({cinemaId: 1});
 *
 * @param params
 * @returns {number}
 */
export async function getEventRequestCount(params?: GetEventRequestCountQueryParameter): Promise<number> {
    return apiClient
        .get<EventRequestCountDto>('/analytics/event-requests-count' + buildUrlQuery(params))
        .then((response) => response.data.count)
        .catch(() => {
            return null;
        });
}

// <------------------------ Messages Count ------------------------>

type GetMessagesCountQueryParameter = UrlQueryParameter & {
    isRead?: boolean;
    isReceivedClient?: boolean;
    isClicked?: boolean;
    userId?: number;
    cinemaId?: number;
    eventName?: string;
    context?: string;
    limit?: number;
};

/**
 * This endpoint returns the number of messages.
 *
 * @example
 * // Get the number of unread messages of a specific user:
 * getMessagesCount({userId: 1, isRead: false});
 *
 * @param params
 * @returns {number}
 */
export async function getMessagesCount(params?: GetMessagesCountQueryParameter): Promise<MessagesCountDto> {
    return apiClient
        .get<MessagesCountDto>('/analytics/messages-count' + buildUrlQuery(params))
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Messages Group Count ------------------------>

type GetMessagesCountGroupedQueryParameter = UrlQueryParameter & {
    group: AnalyticsApiGroup;
    isRead?: boolean;
    isReceivedClient?: boolean;
    isClicked?: boolean;
    userId?: number;
    cinemaId?: number | string;
    contentId?: number | string;
    eventName?: string;
    context?: string;
    createdDatetimeStart?: string;
    createdDatetimeEnd?: string;
};

/**
 * This endpoint returns the number of messages.
 *
 * @example
 * // Get the number of unread messages of a specific user:
 * getMessagesCountGrouped({cinemaId: 1, createdDatetimeStart: ..., createdDatetimeEnd: ...});
 *
 * @param params
 * @returns {number}
 */
export async function getMessagesCountGrouped(
    params?: GetMessagesCountGroupedQueryParameter
): Promise<MessagesCountGroupedDto[]> {
    return apiClient
        .get<MessagesCountDto>('/analytics/messages-count-grouped' + buildUrlQuery(params))
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Movie Ratings Average ------------------------>

type GetMovieRatingsAverageQueryParameter = UrlQueryParameter & {
    cineamoMovieId?: string;
};

/**
 * This endpoint returns the average rating of a movie.
 *
 * @example
 * // Get the average rating of a movie:
 * getMovieRatingsAverage({cineamoMovieId: '155m'});
 *
 * @param params
 * @returns {number}
 */
export async function getMovieRatingsAverage(params?: GetMovieRatingsAverageQueryParameter): Promise<number> {
    return apiClient
        .get('/analytics/movie-ratings-average' + buildUrlQuery(params))
        .then((response) => response.data.average)
        .catch(() => {
            return null;
        });
}

// <------------------------ Talks Count ------------------------>

type GetTalksCountQueryParameter = UrlQueryParameter & {
    userId?: string;
};

/**
 * This endpoint returns the number of talks.
 *
 * @example
 * // Get the number of talks a user has created:
 * getTalksCount({userId: 1});
 *
 * @param params
 * @returns {number}
 */
export async function getTalksCount(params?: GetTalksCountQueryParameter): Promise<number> {
    return apiClient
        .get('/analytics/threads-count' + buildUrlQuery(params))
        .then((response) => response.data.count)
        .catch(() => {
            return null;
        });
}

// <------------------------ User Feedbacks Average ------------------------>

type GetUserFeedbacksAverageQueryParameter = UrlQueryParameter & {
    context?: string;
};

/**
 * This endpoint returns the user feedbacks average.
 *
 * @example
 * // Get the user feedbacks average for a specific context:
 * getUserFeedbacksAverage({context: 'registration'});
 *
 * @param params
 * @returns {number}
 */
export async function getUserFeedbacksAverage(params?: GetUserFeedbacksAverageQueryParameter): Promise<number> {
    return apiClient
        .get('/analytics/user-feedbacks-average' + buildUrlQuery(params))
        .then((response) => response.data.average)
        .catch(() => {
            return null;
        });
}

// <------------------------ User Feedbacks Count ------------------------>

type GetUserFeedbacksCountQueryParameter = UrlQueryParameter & {
    context?: string;
};

/**
 * This endpoint returns the number of user feedbacks.
 *
 * @example
 * // Get the number of user feedbacks for a specific context:
 * getUserFeedbacksCount({context: 'registration'});
 *
 * @param params
 * @returns {number}
 */
export async function getUserFeedbacksCount(params?: GetUserFeedbacksCountQueryParameter): Promise<number> {
    return apiClient
        .get('/analytics/user-feedbacks-count' + buildUrlQuery(params))
        .then((response) => response.data.count)
        .catch(() => {
            return null;
        });
}

// <------------------------ Users Count ------------------------>

type GetUsersCountQueryParameter = UrlQueryParameter & {
    isActivated?: boolean;
    employerCinemaId?: number;
    employerDistributorId?: number | string;
    favoriteCinemaId?: number | string;
    deviceType?: DeviceType;
    createdDatetimeStart?: string;
    registerDatetimeStart?: string;
    registerDatetimeEnd?: string;
    endDate?: string;
};

/**
 * This endpoint returns the number of users.
 *
 * @example
 * // Get the number of users which are activated and the device type is `apple`:
 * getUsersCount({isActivated: true, deviceType: 'apple'});
 *
 * @param params
 * @returns {number}
 */
export async function getUsersCount(params?: GetUsersCountQueryParameter): Promise<number> {
    return apiClient
        .get('/analytics/users-count' + buildUrlQuery(params))
        .then((response) => response.data.count)
        .catch(() => {
            return null;
        });
}

// <------------------------ Users Group Count ------------------------>

type GetUsersCountGroupedQueryParameter = UrlQueryParameter & {
    group: AnalyticsApiGroup;
    isActivated?: boolean;
    employerCinemaId?: number | string;
    employerDistributorId?: number | string;
    favoriteCinemaId?: number | string;
    deviceType?: DeviceType;
    registerDatetimeStart?: string;
    registerDatetimeEnd?: string;
};

/**
 * This endpoint returns the number of users grouped by register-day/age/etc.
 *
 * @example
 * // Get the number of users by age:
 * getUsersCountGrouped({group: 'age', favoriteCinemaId: 123});
 *
 * @param params
 * @returns {UsersGroupCountDto[]}
 */
export async function getUsersCountGrouped(params?: GetUsersCountGroupedQueryParameter): Promise<UsersGroupCountDto[]> {
    return apiClient
        .get('/analytics/users-count-grouped' + buildUrlQuery(params))
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ UserMovies Count ------------------------>

type GetUserMoviesCountQueryParameter = UrlQueryParameter & {
    cinemaId?: number | string;
    eventRequestId?: number | string;
    showingId?: number | string;
};

/**
 * This endpoint returns the number of marketing-assistant recommendation notifications.
 *
 * @example
 * getUserMoviesCount({cinemaId: 123, eventRequestId: 123});
 *
 * @param params
 * @returns {UserMoviesCountDto[]}
 */
export async function getUserMoviesCount(params?: GetUserMoviesCountQueryParameter): Promise<UserMoviesCountDto> {
    return apiClient
        .get('/analytics/user-movies-count' + buildUrlQuery(params))
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ UserMovies Group Count ------------------------>

type GetUserMoviesCountGroupedQueryParameter = UrlQueryParameter & {
    cinemaId?: number | string;
    group: AnalyticsApiGroup;
};

/**
 * This endpoint returns the number of marketing-assistant recommendation notifications, grouped by day.
 *
 * @example
 * getUserMoviesCountGrouped({group: 'notification_day', cinemaId: 123});
 *
 * @param params
 * @returns {UserMoviesCountGroupedDto[]}
 */
export async function getUserMoviesCountGrouped(
    params?: GetUserMoviesCountGroupedQueryParameter
): Promise<UserMoviesCountGroupedDto[]> {
    return apiClient
        .get('/analytics/user-movies-count-grouped' + buildUrlQuery(params))
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Ticket Purchase Intents Count ------------------------>

type GetTicketPurchaseIntentsCountQueryParameter = UrlQueryParameter & {
    cinemaId?: number | string;
    showingId?: number | string;
    purchaseReason?: PurchaseReasonEnum;
};

/**
 * This endpoint returns the number of ticket purchase intents.
 *
 * @example
 * getTicketPurchaseIntentsCount({cinemaId: 123, showingId: 456});
 *
 * @param params
 * @returns {TicketPurchaseIntentsCountDto}
 */
export async function getTicketPurchaseIntentsCount(
    params?: GetTicketPurchaseIntentsCountQueryParameter
): Promise<TicketPurchaseIntentsCountDto> {
    return apiClient
        .get('/analytics/ticket-purchase-intents-count' + buildUrlQuery(params))
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Ticket Purchase Intents Group Count ------------------------>

type getTicketPurchaseIntentsCountGroupedQueryParameter = UrlQueryParameter & {
    cinemaId?: number | string;
    contentId?: number | string;
    showingId?: number | string;
    purchaseReason?: PurchaseReasonEnum;
    createdDatetimeStart: string;
    createdDatetimeEnd: string;
    group: AnalyticsApiGroup;
};

/**
 * This endpoint returns the number of ticket purchase intents.
 *
 * @example
 * getTicketPurchaseIntentsCountGrouped({cinemaId: 123, showingId: 456});
 *
 * @param params
 * @returns {TicketPurchaseIntentsCountDto}
 */
export async function getTicketPurchaseIntentsCountGrouped(
    params?: getTicketPurchaseIntentsCountGroupedQueryParameter
): Promise<TicketsPurchaseIntentsCountGrouped[]> {
    return apiClient
        .get('/analytics/ticket-purchase-intents-count-grouped' + buildUrlQuery(params))
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}
